export default {
  async update (Vue, id, formData) {
    const respOperacionCRM = await Vue.$api.call(
      'operacioncrm.update',
      {
        values: {
          idoperacioncrm: id,
          idtoperacioncrm: formData.idtoperacioncrm?.idtoperacioncrm || null,
          idserie_operacioncrm: formData.idserie_operacioncrm?.idserie_operacioncrm || null,
          idprioridad: formData.idprioridad,
          descripcion: formData.descripcion,
          observaciones: formData.observaciones,
          iddelegacion: formData.iddelegacion,
          iddepartamento: formData.iddepartamento,
          idresponsable: formData.responsable.idempleado,
          fproximarevision: formData.fproximarevision,
          fprevistafin: formData.fprevistafin,
          ffin: formData.ffin,
          idest_operacioncrm: formData.idest_operacioncrm,
        },
      },
    )
    return respOperacionCRM.data.result.dataset[0]
  },
}
